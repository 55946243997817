<div class="m0-main-container y3-roboto">

  <div style="height: 80px; background-color: white; position: sticky; top:0; width: 100%; z-index: 900;">

    <div class="m0-burger-menu-langue">
      <button mat-icon-button [matMenuTriggerFor]="menuof" >
        <img class="m0-flag " src="{{utilService.getLanguageFlag(translateService.currentLang)}}" alt="Flag">
      </button>
      <mat-menu #menuof="matMenu" >
        <button *ngFor="let l of getLanguages()"  mat-menu-item (click)="changeLanguage(l)" style="line-height: 25px; height: 25px;">
          <div class="y3-flex-row y3-flex-align-center" >
            <img class="m0-flag " src="{{utilService.getLanguageFlag(l)}}" alt="Flag">
            <div class="padding-left-8 y3-Small">{{getNonTranslatedLanguageName(l)}}</div>
          </div>
        </button>
      </mat-menu>
    </div>

    <div class="y3-flex-row y3-flex-justify-content-center" >
      <!--<img class="m0-corporate-fav" [src]="corporate.favLogoUrl" alt="corporate logo" *ngIf="corporate.favLogoUrl">-->
      <img class="m0-corporate-fav" [src]="corporate.logoUrl" alt="corporate logo" *ngIf="corporate.logoUrl">
    </div>


    <div class="m0-burger-menu">



      <div style="position: relative  ">
        <button mat-icon-button [matMenuTriggerFor]="menu" >

          <mat-icon>menu</mat-icon>
        </button>

      </div>
      <mat-menu #menu="matMenu" >
        <button *ngIf="booking != null && !property.noPreCheckInButton" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-online-check-in']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">verified</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.check-in' | translate}}</span>
        </button>
        <button  mat-menu-item [routerLink]="['/guest/' + idForRouting  +'/mobile-property']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">home</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.details' | translate}}</span>
        </button>
        <button *ngIf="property.poi"  mat-menu-item (click)="utilService.findDirection(this.property)">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">location_on</mat-icon>
          <span class="y3-title-colors">{{'guest-detail-booking.find-direction' | translate}}</span>
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="!property.noshowRecommendationOnBooklet && property.poi" mat-menu-item (click)="showMap()">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">favorite</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.reco' | translate}}</span>
        </button>
        <button *ngIf="hasUpSells  && this.booking != null"   mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-upsells']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">room_service</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.upsells' | translate}}</span>
        </button>

        <!--<button  mat-menu-item >
          <mat-icon class="color-icon">play_lesson</mat-icon>
          <span class="">{{'Guides' | translate}}</span>
        </button>-->
        <mat-divider *ngIf="booking"></mat-divider>

        <button *ngIf="property.usefulNumbers != null && property.usefulNumbers?.length > 0 && booking"  mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-numbers']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">call</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.numbers' | translate}}</span>
        </button>
        <button *ngIf="!property?.noshowPlatformServicesOnBooklet && booking" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-services']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">note_add</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.other-services' | translate}}</span>
        </button>
        <button *ngIf="!property?.noshowHygieneOnBooklet && booking && property?.forceMajeureItems.length > 0" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-hygien']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">medical_information</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.hygien' | translate}}</span>
        </button>
        <button  *ngIf="booking" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile/review']" >
          <mat-icon class="color-icon-for-menu y3-color-background-icons">star</mat-icon>
          <span class="">{{'Commentaires' | translate}}</span>
        </button>
        <mat-divider *ngIf="booking || property.showMeAsHost"></mat-divider>
        <button *ngIf="booking" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile/booklet-review']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">star</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.booklet-review' | translate}}</span>
        </button>
        <button *ngIf="property.showMeAsHost" mat-menu-item [routerLink]="['/guest/' + idForRouting +'/mobile-details']">
          <mat-icon style="line-height: 24px" class="color-icon-for-menu y3-color-background-icons">phone</mat-icon>
          <span class="y3-title-colors">{{'m0-landing-page.contact' | translate}}</span>
        </button>
      </mat-menu>
    </div>

  &nbsp;
  </div>

  <div style=" position: relative">

    <div class="m0-top-image-container" >
    <img class="m0-top-image" [src]="mainPropertyPhoto" alt="" >
    <div class="y3-flex-row y3-flex-justify-content-center" >

      <button *ngIf="_hasPrevious" appDebounceClick (debounceClick)="previous()" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
        <mat-icon style="line-height: 24px;">arrow_back_ios_new</mat-icon>
      </button>
      <button *ngIf="_hasNext" appDebounceClick (debounceClick)="next()" class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
        <mat-icon style="line-height: 24px;">arrow_forward_ios</mat-icon>
      </button>

    </div>

    <!--<div class="m0-welcome">

    </div>-->





    <!--<div class="y3-flex-row m0-button-quick-container">
      <div class="y3-margin-right-8">
        <mat-icon class="m0-button-quick">home</mat-icon>
      </div>
      <div class="y3-margin-right-8">
      <mat-icon class="m0-button-quick">storefront</mat-icon>
      </div>
      <div class="y3-margin-right-8">
        <mat-icon class="m0-button-quick">shopping_cart</mat-icon>
      </div>
      <div>
        <mat-icon class="m0-button-quick">location_on</mat-icon>
      </div>
    </div>-->




    </div>
    <div class="m0-detail-container y3-margin-top-8" >

      <div class="m0-card-icons y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-text-ellipsis y3-full-width y3-button-colors" *ngIf="booking">
        <div class="y3-Medium y3-margin-bottom-4 y3-bold y3-title-colors">{{'m0-landing-page.welcome' | translate}} {{booking.guests[0].firstName | titlecase}} {{booking.guests[0].lastName | titlecase}}</div>
        <div *ngIf="booking != null && booking.hostWords === '' || booking.hostWords == null" class="y3-X-small">{{'m0-landing-page.guest-space' | translate}}</div>
        <div *ngIf="booking && booking.hostWords" class="y3-X-small">{{booking?.hostWords}}</div>
      </div>

      <div class="y3-flex-row y3-margin-top-16 y3-margin-bottom-8 y3-flex-justify-space-between m0-button-quick-container ">

        <div  class=" m0-card-icons y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-button-colors" [routerLink]="['/guest/' + idForRouting +'/mobile-property']">
          <mat-icon class="m0-button-quick y3-color-background-icons">home</mat-icon>
          <div class="y3-X-small y3-margin-top-4 " style="text-align: center" >{{'m0-landing-page.details' | translate}}</div>
        </div>

        <!--<div *ngIf="hasUpSells && this.booking != null" class="y3-margin-right-8" [routerLink]="['/guest/' + idForRouting +'/mobile-upsells']">
          <mat-icon class="m0-button-quick">room_service</mat-icon>
        </div>-->

        <div *ngIf="property.poi" class=" m0-card-icons  y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-button-colors" (click)="utilService.findDirection(this.property)">
          <mat-icon class="m0-button-quick y3-color-background-icons">location_on</mat-icon>
          <div class="y3-X-small y3-margin-top-4 " style="text-align: center" >{{'m0-landing-page.get-there' | translate}}</div>
        </div>
        <div *ngIf="!property.noshowRecommendationOnBooklet && property.poi" class=" m0-card-icons y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-button-colors" (click)="showMap()">
          <mat-icon class="m0-button-quick y3-color-background-icons">favorite</mat-icon>
          <div class="y3-X-small y3-margin-top-4 " style="text-align: center" >{{'m0-landing-page.favorits' | translate}}</div>
        </div>

        <div *ngIf="booking && !property.noShowChatContact" class=" m0-card-icons y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-button-colors" style="position: relative" [routerLink]="['/guest/' + idForRouting +'/mobile-chat']">
          <mat-icon class="m0-button-quick y3-color-background-icons">forum</mat-icon>
          <div *ngIf="hasUnreadMessages" class="m0-badge">
            &nbsp;
          </div>
          <div class="y3-X-small y3-margin-top-4 " style="text-align: center" >{{'m0-landing-page.msg' | translate}}</div>
        </div>

        <!--<div *ngIf="corporate.showMeAsHost" class="y3-flex-column m0-card-icons y3-flex-justify-content-center y3-flex-align-center" [routerLink]="['/guest/' + idForRouting +'/mobile-details']">
          <mat-icon class="m0-button-quick">phone</mat-icon>
          <div class="y3-X-small y3-margin-top-4 " style="text-align: center" >Contact</div>
        </div>-->
      </div>


      <div class="y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-text-ellipsis y3-margin-top-16 y3-margin-bottom-8 y3-full-width">
        <div class="y3-Large y3-bold y3-title-colors" style="text-align: center">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
        <div class="y3-X-small y3-margin-top-4 y3-title-colors" style="text-align: center">{{property.fullAddress}}</div>
      </div>


      <div class="y3-margin-bottom-16" *ngIf="currentUpSellOnBoarding" >
        <app-m24-upsells-recap ></app-m24-upsells-recap>
      </div>
      <app-m23-upsell-recap *ngIf="checkIfUpSell()" [currentUpSellOnBoarding]="currentGuestUpsellOnBoarding"></app-m23-upsell-recap>
      <div class="y3-margin-bottom-16" *ngIf="previousUpSellOnBoardings?.length > 0" >
        <app-m25-upsells-validated></app-m25-upsells-validated>
      </div>






      <!--<div class="y3-Large y3-margin-bottom-4 y3-bold">Bienvenue {{booking.guests[0].firstName | titlecase}}</div>
      <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-full-width">
        <div class="y3-color-white">
          <div class="y3-Medium y3-bold">{{property.title}}</div>
          <div class="y3-Small">{{property.fullAddress}}</div>
        </div>
        <div class="m0-icon-map">
          <mat-icon>travel_explore</mat-icon>
        </div>
      </div>-->


      <div class="" *ngIf="booking">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <div class="m0-check-card">
            <div class="y3-flex-row">
              <img src="assets/images/illustrations/men-fly.svg" alt="start">
              <div class="y3-flex-column y3-margin-left-16">
                <div class="y3-Medium y3-bold">{{'m0-landing-page.check-in' | translate}}</div>
                <div class="y3-X-small y3-color-scorpion y3-margin-top-4 y3-nowrap">{{booking.startDate | moment: translateService.currentLang  | titlecase}}</div>
                <!--TODO check if it is good to force checkin online time orr what to do-->
                <!--<div *ngIf="booking?.onLineCheck" class="y3-Small y3-color-scorpion y3-margin-top-4">{{booking?.onLineCheck?.onLineCheckInTime?.substring(0,5)}}</div>-->
                <div  class="y3-Small y3-color-scorpion y3-margin-top-4">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
                <!--
                              <div *ngIf="booking?.onLineCheck == null && booking?.expectedCheckinLocalTime == null" class="y3-Small y3-color-scorpion y3-margin-top-2">{{property.defaultCheckInTime}}</div>
                -->
              </div>
            </div>
          </div>
          <div class="m0-check-card">
            <div class="y3-flex-row">
              <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
              <div class="y3-flex-column y3-margin-left-16">
                <div class="y3-Medium y3-bold">{{'m0-landing-page.check-out' | translate}}</div>
                <div class="y3-X-small y3-color-scorpion y3-margin-top-4 y3-nowrap">{{booking.endDate | moment: translateService.currentLang  | titlecase}}</div>
                <!-- <div *ngIf="booking.onLineCheck" class="y3-Small y3-color-scorpion y3-margin-top-4">{{booking?.onLineCheck?.onLineCheckOutTime?.substring(0,5)}}</div>-->
                <div  class="y3-Small y3-color-scorpion y3-margin-top-4">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
                <!--<div *ngIf="booking.onLineCheck == null && booking.expectedCheckoutLocalTime != null" class="y3-Small y3-color-scorpion y3-margin-top-4">{{booking.expectedCheckoutLocalTime}}</div>
                <div *ngIf="booking.onLineCheck == null && booking.expectedCheckoutLocalTime == null" class="y3-Small y3-color-scorpion y3-margin-top-2">{{property.defaultCheckOutTime}}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="y3-margin-top-24">
        <mat-icon class="m0-icon-3">real_estate_agent</mat-icon>
      </div>-->


      <div class="m0-bottom-button-container" >


        <div *ngIf="booking != null && !canShowSmartLock() && (doorCode || doorLink || doorLinkToClose || booking.smartLockAccessList?.length > 0)" class="y3-margin-top-6 m0-new-button-without-shadow " style="background-color: #FCF2E3" >
          <div class="y3-flex-row">
            <mat-icon class="m0-icon-1">lock</mat-icon>
            <div class="y3-flex-column y3-padding-left-24 y3-flex-justify-content-center" >
              <div class="y3-X-small ">{{'guest-detail-booking.smartlock-available-day'  | translate}}</div>
              <div class="y3-X-small y3-bold">{{getSmartLockAvailableDay() | moment: translateService.currentLang  | titlecase}}</div>
            </div>
          </div>
        </div>



        <!-- CASE NUKI -->
        <div class="" *ngIf="canShowSmartLock() && isCautionDeposed() && isSmartLockCodeAllowToShow() && booking != null && booking.smartLockAccessList?.length > 0">

          <div class="y3-margin-top-6 m0-new-button-without-shadow" *ngFor="let smartlock of booking.smartLockAccessList">
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
              <div class="y3-flex-row">
                <mat-icon class="m0-icon-1">lock</mat-icon>
                <div class="y3-flex-column y3-padding-left-24" >
                  <div class="y3-X-small y3-color-scorpion">{{smartlock.smartLockYaagoDisplayName ? smartlock.smartLockYaagoDisplayName : smartlock.smartLockName}}</div>
                  <div *ngIf="smartlock.smartLockCode" class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{smartlock.smartLockCode}}</div>
                  <div *ngIf="!smartlock.smartLockCode " class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{'m0-landing-page.click-to-open-close' | translate}}</div>
                </div>
              </div>

              <div class="y3-flex-row y3-flex-align-center">
                <a style="text-decoration: none;" class="y3-margin-right-8"  *ngIf="smartlock.urlToClose && displayDuringStay(smartlock)"  href="{{smartlock.urlToClose}}" target="_blank"><mat-icon class="m0-button-quick-2 ">lock</mat-icon>
                </a>

                <a style="text-decoration: none" *ngIf="smartlock.urlToOpen && displayDuringStay(smartlock)" href="{{smartlock.urlToOpen}}" target="_blank"><mat-icon class="m0-button-quick-2">lock_open</mat-icon>
                </a>
              </div>

            </div>
          </div>

        </div>


        <section id="_____SECTION____KEYNEST_CODE__AVAILABLE" >

          <div class="y3-margin-top-6 m0-new-button-without-shadow"  *ngIf="booking != null && booking.keynestAccess != null && isCautionDeposed() && isSmartLockCodeAllowToShow()">
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
              <div class="y3-flex-row">
                <!--<mat-icon class="m0-icon-1">lock</mat-icon>-->
                <img src="assets/images/partners/Keynest-icon.png" alt="keynest" style="width: 35px; height: 35px;">
                <div class="y3-flex-column y3-padding-left-24" >
                  <div class="y3-X-small y3-color-scorpion">{{'Keynest Collection Code'  | translate}}</div>
                  <div *ngIf="booking?.keynestAccess?.CollectionCode" class="y3-margin-top-4 y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{booking?.keynestAccess?.CollectionCode}}</div>
                </div>
              </div>

              <div class="y3-flex-row y3-flex-align-center">
                <a style="text-decoration: none;" *ngIf="booking?.keynestAccess?.CollectionCodeMagicLink"  href="{{booking?.keynestAccess?.CollectionCodeMagicLink}}" target="_blank"><mat-icon class="m0-button-quick-2 ">lightbulb</mat-icon>
                </a>
              </div>

            </div>
          </div>
        </section>


        <!-- IGLOOHOME -->
        <div class="y3-margin-top-6" *ngIf="booking != null && (doorCode || doorLink || doorLinkToClose )
            && isCautionDeposed() && canShowSmartLock()
            && isSmartLockCodeAllowToShow()"
            >
          <div class="m0-new-button-without-shadow">
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
              <div class="y3-flex-row">
                <mat-icon class="m0-icon-1">lock</mat-icon>
                <div class="y3-flex-column y3-padding-left-24" *ngIf="doorCode">
                  <div *ngIf="!isLockIcon" class="y3-X-small y3-color-scorpion">{{'m0-landing-page.lock-code' | translate}}</div>

                  <div *ngIf="isLockIcon && booking.iglooHomeAccess?.lockId?.startsWith('IGM')" class="y3-X-small y3-color-scorpion">{{'guest-detail-booking.smartlock'  | translate}}</div>
                  <div *ngIf="isLockIcon && booking.iglooHomeAccess?.lockId?.startsWith('IGK')" class="y3-X-small y3-color-scorpion">{{'guest-detail-booking.smartkeybox'  | translate}}</div>


                  <div class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{utilService.formatIgloohomeCode(doorCode)}}<span *ngIf="isLockIcon">🔒</span></div>
                </div>
              </div>

              <a style="text-decoration: none;" class="y3-margin-right-8"  *ngIf="doorLinkToClose"  href="{{doorLinkToClose}}" target="_blank"><mat-icon class="m0-button-quick-2 ">lock</mat-icon>
              </a>

              <a style="text-decoration: none" *ngIf="doorLink" href="{{doorLink}}" target="_blank"><mat-icon class="m0-button-quick-2">lock_open</mat-icon>
              </a>

            </div>
          </div>
        </div>

        <!--<div class="y3-margin-top-6" *ngIf="hasImportantInfo">
          <div class="m0-new-button" [routerLink]="['/guest/' + idForRouting +'/mobile-important']">
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
              <div class="y3-flex-row">
                <mat-icon class="m0-icon-1">info</mat-icon>
                <div class="y3-flex-column y3-padding-left-24 y3-flex-justify-content-center">
                  <div class="y3-Medium y3-bold y3-margin-top-3 ">{{'m0-landing-page.important' | translate}}</div>
                </div>
              </div>
              <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>
            </div>
          </div>
        </div>-->

        <div class="y3-margin-top-6" *ngIf="booking != null && canShowDeposit() && booking.swiklyInfo != null && booking.swiklyInfo.swiklyAcceptUrl != null">
          <a style="text-decoration: none; color: black" href="{{booking.swiklyInfo?.swiklyAcceptUrl}}" target="_blank">
            <div class="m0-new-button">
              <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
                <div class="y3-flex-row">
                  <mat-icon class="m0-icon-1">credit_card</mat-icon>
                  <div class="y3-flex-column y3-padding-left-24 ">
                    <div class="y3-X-small y3-color-scorpion">{{'m0-landing-page.deposit' | translate}}</div>
                    <div class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{'m0-landing-page.do-deposit' | translate}}</div>
                  </div>
                </div>
                <mat-icon *ngIf="booking.swiklyInfo?.swiklyDepositAcceptedDate == null" class="m0-button-quick-2">chevron_right</mat-icon>
                <mat-icon *ngIf="booking.swiklyInfo?.swiklyDepositAcceptedDate != null" class="m0-button-quick-done">done</mat-icon>
              </div>
            </div>
          </a>
        </div>

        <section *ngIf="(property?.checkInOnline || property?.lightOnLinePreCheck) && !property.noPreCheckInButton">
          <div class="y3-margin-top-6" *ngIf="!isDuringTheStay() && this.booking != null" [routerLink]="['/guest/' + idForRouting +'/mobile-online-check-in']">
            <div class="m0-new-button">
              <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
                <div class="y3-flex-row">
                  <mat-icon class="m0-icon-1">verified</mat-icon>
                  <div class="y3-flex-column y3-padding-left-24">
                    <div class="y3-X-small y3-color-scorpion">{{'m0-landing-page.online' | translate}}</div>
                    <div class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{'m0-landing-page.record' | translate}}</div>
                  </div>
                </div>

                <mat-icon *ngIf="onlineCheck == null || onlineCheck?.checkStatus ===  'EMPTY' || onlineCheck?.checkStatus === 'NOUVEAU'" class="m0-button-quick-2">chevron_right</mat-icon>
                <mat-icon *ngIf="onlineCheck != null && (onlineCheck.checkStatus === 'VALIDATED' || onlineCheck.checkStatus === 'SKIPPED')" class="m0-button-quick-done">done</mat-icon>
              </div>
            </div>
          </div>
        </section>

        <!---->
        <div class="y3-margin-top-6" *ngIf="property.showGuestInventory  && (utilService.isCheckIndDate(booking) || utilService.isCheckOutDate(booking)) && this.booking != null "   [routerLink]="['/guest/' + idForRouting +'/mobile-inventory']">
          <div class="m0-new-button">
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
              <div class="y3-flex-row">
                <mat-icon class="m0-icon-1">photo_camera</mat-icon>
                <div class="y3-flex-column y3-padding-left-24">
                  <div class="y3-X-small y3-color-scorpion">{{'m0-landing-page.etat-des-lieux' | translate}}</div>
                  <div *ngIf="booking?.guestInventoryCheckOut == null || booking?.guestInventoryCheckOut?.checkAt == null" class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{'m0-landing-page.start' | translate}}</div>
                  <div *ngIf="booking?.guestInventoryCheckOut?.checkAt != null && booking?.guestInventoryCheckOut?.checkAt != null" class="y3-Small y3-bold y3-margin-top-3 y3-color-banner-font">{{'m0-landing-page.edit' | translate}}</div>
                </div>
              </div>
              <mat-icon *ngIf="booking?.guestInventoryCheckOut == null || booking?.guestInventoryCheckOut?.checkAt == null" class="m0-button-quick-2">chevron_right</mat-icon>
              <mat-icon *ngIf="booking?.guestInventoryCheckOut?.checkAt != null && booking?.guestInventoryCheckOut?.checkAt != null" class="m0-button-quick-done">done</mat-icon>

            </div>
          </div>
        </div>

      </div>



    </div>



    <div style="">
      <app-m16-important-guide-top *ngIf="hasImportantInfo"></app-m16-important-guide-top>
      <app-m6-upsells id="upsells" #upsells *ngIf="booking && hasUpSells" [embedded]="true" (getClosed)="reloadBasket()"></app-m6-upsells>
      <app-m3-guides-top id="guides" #guides ></app-m3-guides-top>
      <app-m2-recommendation-top4 id="recommendations" #recommendations *ngIf="!property.noshowRecommendationOnBooklet && property.poi"></app-m2-recommendation-top4>



      <div *ngIf="corporate?.corporateTag">
        <app-cotoon-footer *ngIf="corporate?.corporateTag === 'COTOON'" ></app-cotoon-footer>
        <!--<app-rentatranquille-footer *ngIf="corporate?.corporateTag === 'RENTATRANQUILLE'" ></app-rentatranquille-footer>-->
        <!--<app-guest-adom-footer *ngIf="corporate?.corporateTag === 'GUESTADOM'" ></app-guest-adom-footer>-->
        <app-keylodge-footer  *ngIf="corporate?.corporateTag === 'KEYLODGE'"></app-keylodge-footer>

        <app-gites-de-france *ngIf="corporate?.corporateTag === 'GITESDEFRANCE'" ></app-gites-de-france>
        <app-haloconcierge *ngIf="corporate?.corporateTag === 'HALOCONCIERGE'" ></app-haloconcierge>
        <app-yaago2-powered-by-footer [corporate]="corporate"></app-yaago2-powered-by-footer>
      </div>

      <!--<div class="empty-space" *ngIf="checkIfUpSell()"></div>
      <div class="empty-space" *ngIf="currentUpSellOnBoarding != null && currentUpSellOnBoarding.paymentIntentStatus == null"></div>-->
    </div>

  </div>




</div>



<!--<div class="upsell-container" *ngIf="checkIfUpSell()">
  <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-padding-8 y3-X-small" style="height: 100%">
    <div></div>
    <div class="y3-flex-row y3-flex-align-center">
      <div class="y3-margin-right-16" style="color: white">{{'menu-desktop.basket-content' | translate}} {{getNumbers()}}</div>
      <div class="y3-flex-column y3-flex-justify-content-end y3-flex-align-end">
        <div class="y3-margin-bottom-8">
          <app-button [text]="'menu-desktop.checkout-basket' | translate" [type]="'primary-x-small'" (debounceClick)="validateUpsell()"></app-button>
        </div>
        <div>
          <app-button [text]="'menu-desktop.remove-basket' | translate" [type]="'secondary-x-small-white'" (debounceClick)="resetBasket()"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>-->

<!--<div class="upsell-waiting-container" *ngIf="currentUpSellOnBoarding != null && currentUpSellOnBoarding.paymentIntentStatus == null">
  <div class="y3-flex-row y3-flex-justify-content-center y3-flex-align-center y3-padding-8 y3-Small" style="height: 100%">
    <div class="y3-flex-column y3-flex-align-center">
      <div class="y3-flex-row y3-flex-align-center y3-margin-bottom-8">
        <mat-icon>warning</mat-icon>
        <div class="y3-margin-right-16 y3-margin-left-8">{{'menu-desktop.add-paiment-method-descr' | translate}}</div>
      </div>
      <div>
        <app-button [text]="'menu-desktop.add-paiment-method' | translate" [type]="'secondary-x-small-white'" (debounceClick)="putPaymentMethod()"></app-button>
      </div>
    </div>
  </div>
</div>-->

